const truncate = require("html-truncate");

import { TRUNCATE_CHAR_LENGTH } from "./constants";
import { SplittedContentType } from "./types";

const splitContentItem = (content: string): SplittedContentType => {
  const truncatedString = truncate(content, TRUNCATE_CHAR_LENGTH, { ellipsis: false, keepImageTag: true });
  // html-truncate incorrectry truncates img with srcset attribute and some others
  // Removing such img's from truncatedString to fix this
  const fixedTruncatedString = truncatedString.replace(/<p><img [^>]*<\/p>/gm, "");
  return {
    visibleContent: fixedTruncatedString,
    hiddenContent: content.substring(fixedTruncatedString.length),
  };
}

export const splitContent = (
  textTop: string | undefined,
  textMiddle: string | undefined,
  textBottom: string | undefined,
): SplittedContentType => {
  const emptyResult = { visibleContent: "", hiddenContent: "" };
  if (!textTop && !textMiddle && !textBottom) {
    return emptyResult;
  }
  if (textTop && textTop?.length > TRUNCATE_CHAR_LENGTH) {
    return splitContentItem(textTop);
  }
  if (textMiddle && textMiddle?.length > TRUNCATE_CHAR_LENGTH) {
    return splitContentItem(textMiddle);
  }
  if (textBottom && textBottom?.length > TRUNCATE_CHAR_LENGTH) {
    return splitContentItem(textBottom);
  }
  return emptyResult;
};