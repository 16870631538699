import React, { useContext, useEffect, useState, FC } from "react";
import { Helmet } from "react-helmet";

import { useLocation } from "@reach/router";
import { getGoroskopLeadFormSentValue } from "@/account/utils";
import { isBrowser } from "@/utils/env";

import { FormTypeEnum } from "@/components/layout/modals/types";
import { setAuthParamToURL } from "@/components/layout/modals/utils";
import { UserContextType } from "@/contexts/User/types";
import UserContext from "@/contexts/User/UserContext";
import { cleanupFromDangerousTags } from "@/utils/stringUtils";
import LeadForm from "../leadForm";
import { splitContent } from "./utils";
import { BlurredContentHocProps } from "./types";
import { PAYWALL_CLASS_NAME, PAYWALL_LD_JSON_OBJECT } from "./constants";
import "./styles.scss";

const BlurredContentHoc: FC<BlurredContentHocProps> = ({
  textBottom,
  textMiddle,
  textTop,
  fieldItems,
  textClassName,
  children,
}) => {
  const [shouldRenderContent, setShouldRenderContent] = useState(!isBrowser());
  const { isUserLoggedIn } = useContext<UserContextType>(UserContext);

  const location = useLocation();
  const isSubscribed = getGoroskopLeadFormSentValue();

  const onAuthorizationLinkClick = () => {
    setAuthParamToURL(location, FormTypeEnum.Login);
  };

  const splittedContent = splitContent(textTop, textMiddle, textBottom);

  useEffect(() => {
    if (isUserLoggedIn || !splittedContent.hiddenContent || isSubscribed) setShouldRenderContent(true);
  }, [isSubscribed, isUserLoggedIn, splittedContent]);

  if (shouldRenderContent) {
    return <div>{children}</div>
  }

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(PAYWALL_LD_JSON_OBJECT)}</script>
      </Helmet>
      <div className="parent-blurred">
        <div
          className={textClassName}
          dangerouslySetInnerHTML={{
            __html: cleanupFromDangerousTags(splittedContent.visibleContent),
          }}
        />
        <div className="blurred-section" />
      </div>
      <div className="blurred-content__wrapper">
        <div className="blurred-content__lead">
          <LeadForm
            title="Подпишитесь, чтобы дочитать"
            description="<p>Это бесплатно🎁 После подписки вы получите доступ
ко всей статье и советам астрологов для вашего знака.<br/><br/>Уже более <b>18 000 подписчиков</b> читают наши гороскопы,
присоединяйтесь и вы😉</p>"
            formId="signed_blur"
            metricsId="signed_blur"
            fieldItems={fieldItems}
            buttonText="Подписаться"
            successTitle="Значения Арканов: письма тем, кто изучает Таро"
            successDescription="Статьи о том, как трактовать карты, какие расклады бывают, и какие книги по Таро самые авторитетные. В вашей почте раз в неделю. Бесплатно"
            onButtonClickAddition={() => {
              setShouldRenderContent(true);
            }}
          />
          <div className="blurred-content__lead--subscribe">
            <p>
              Уже подписаны? <a onClick={onAuthorizationLinkClick}>Тогда войдите</a>
            </p>
          </div>
        </div>
      </div>
      <div
        className={`blurred-content__hidden ${PAYWALL_CLASS_NAME} ${textClassName}`}
        dangerouslySetInnerHTML={{
          __html: cleanupFromDangerousTags(splittedContent.hiddenContent),
        }}
      />
    </>
  );
};

export default BlurredContentHoc;
