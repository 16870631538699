export const PAYWALL_CLASS_NAME = "paywall";

export const PAYWALL_LD_JSON_OBJECT = {
  "@context": "https://schema.org",
  "@type": "NewsArticle",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://example.org/article"
  },
  "isAccessibleForFree": false,
  "hasPart": {
    "@type": "WebPageElement",
    "isAccessibleForFree": false,
    "cssSelector": `.${PAYWALL_CLASS_NAME}`
  }
};

export const TRUNCATE_CHAR_LENGTH = 1000;